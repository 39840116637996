<template>
  <div class="storiesBody">
    <!-- banner -->
    <section id="banner">
      <div class="inner">
        <h1 v-html="$t('stories.title')"></h1>
        <div
          class="textBlock"
          v-html="$t('stories.desc')"
        ></div>
      </div>
    </section>

    <!-- grid -->

    <div class="row" ref="stories">
      <div class="column">
        <div
          class="cardCollapse"
          v-for="(elem, index) in storiesList1"
          :key="index"
        >
          <el-card class="card">
            <h5 class="pb-2">{{ elem.title }}</h5>
            <el-image
              v-if="elem.img"
              :src="elem.img"
              :preview-src-list="srcList"
              class="pb-2"
            >
              <div slot="error" class="image-slot"></div>
            </el-image>
            <p v-html="elem.desc"></p>
            <div style="text-align:right" v-if="elem.author">
              --{{ elem.author }}
            </div>
          </el-card>
        </div>
      </div>
      <div class="column">
        <div
          class="cardCollapse"
          v-for="(elem, index) in storiesList2"
          :key="index"
        >
          <el-card class="card">
            <h5 class="pb-2">{{ elem.title }}</h5>
            <el-image
              v-if="elem.img"
              :src="elem.img"
              :preview-src-list="srcList"
              class="pb-2"
            >
              <div slot="error" class="image-slot"></div>
            </el-image>
            <p v-html="elem.desc"></p>
            <div style="text-align:right" v-if="elem.author">
              --{{ elem.author }}
            </div>
          </el-card>
        </div>
      </div>
      <div class="column">
        <div
          class="cardCollapse"
          v-for="(elem, index) in storiesList3"
          :key="index"
        >
          <el-card class="card">
            <h5 class="pb-2">{{ elem.title }}</h5>
            <el-image
              v-if="elem.img"
              :src="elem.img"
              :preview-src-list="srcList"
              class="pb-2"
            >
              <div slot="error" class="image-slot"></div>
            </el-image>
            <p v-html="elem.desc"></p>
            <div style="text-align:right" v-if="elem.author">
              --{{ elem.author }}
            </div>
          </el-card>
        </div>
      </div>
    </div>

    <p v-show="srcList.length === 0" style="text-align:center">No More</p>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  data() {
    return {
      pageCount: 1,
      lastPage: 0,
      storiesList1: [],
      storiesList2: [],
      storiesList3: [],
      srcList: [],
      isLoading: false,
      busy: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    const body = document.body;
    body.style.overflowY = "overlay";
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters("stories", ["getCurrentStories"]),
    noMore() {
      return this.pageCount >= this.lastPage;
    }
  },
  watch: {
    getCurrentStories(newVal) {
      const len = newVal.data.length;
      for (let i = 0; i < len; i++) {
        let obj = {
          title: newVal.data[i].title.en,
          img: newVal.data[i].feedback_src,
          desc: newVal.data[i].feedback.cn,
          author: newVal.data[i].name
        };

        this.srcList.push(newVal.data[i].feedback_src);
        if (i % 3 === 1) {
          this.storiesList1.push(obj);
        } else if (i % 3 === 2) {
          this.storiesList2.push(obj);
        } else {
          this.storiesList3.push(obj);
        }
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("stories/getStories");
  },
  methods: {
    async handleScroll() {
      var d = document.documentElement;
      var offset = d.scrollTop + window.innerHeight + 1;
      var height = d.offsetHeight;
      const delay = interval =>
        new Promise(resolve => setTimeout(resolve, interval));

      if (offset >= height) {
        await this.$store.dispatch("stories/getStories", ++this.pageCount);

        await delay(1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#banner {
  text-align: center;
  background-image: url(../../../assets/stories.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  color: #bacee6;
  padding: 8em 0 6em 0;
  height: 520px;
  padding: 0px;
  filter: contrast(90%);
}

#banner .inner {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 150px 0px 50px;
  height: 520px;
}

#banner h1 {
  font-size: 4em;
  line-height: 1em;
  margin: 0 0 0.5em 0;
  padding: 0;
  color: #fff;
  line-height: 100%;
}

#banner .textBlock {
  color: #fff;
  font-size: 1.5em;
  padding: 0px 50px;
  // margin-bottom: 1.75em;
}

@media screen and (max-width: 1280px) {
  #banner h1 {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 736px) {
  #banner {
    background-attachment: scroll;
  }
  #banner h1 {
    font-size: 2.25em;
  }

  #banner .textBlock {
    font-size: 1.25em;
  }
}

.contactUs {
  color: #42a16a;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-right: 0px;
  width: 100%;
}

/* Create three equal columns that sits next to each other */
.column {
  flex: 33.33%;
  max-width: 33.33%;
  padding: 25px;
  // padding-left: 10px;
  width: 100%;
}

.cardCollapse {
  margin-bottom: 30px;
  max-height: 360px;
  overflow: hidden;
  transition: max-height 2s ease-in-out;
}

.cardCollapse:hover {
  max-height: 10000px;
}

.card {
  background-color: #eeeeee;
  // animation: 1s ease-out 0s 1 slideInFromLeft;
  animation: createBox 0.75s;
}

@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
// @media screen and (max-width: 992px) {
//   .column {
//     flex: 50%;
//     max-width: 50%;
//   }
// }

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 768px) {
  .column {
    flex: 100%;
    max-width: 100%;
    padding: 20px 0;
  }
  .cardCollapse {
    margin-bottom: 30px;
    max-height: 10000px;
  }
  #banner .textBlock {
    padding: 0 25px;
  }
  #banner .inner {
    padding: 150px 0px 50px;
  }
}

.el-button {
  outline: none;
}
</style>
